<template>
<div style="display: none;"></div>
</template>
<script>
export default {
  mounted() {
    this.$notify({
        timeout: 10000,
        message:
          "新更新： 系统目前会通过人工智能识别二维码的位置，自动遮盖，以防止因为二维码而被拼多多拒绝。",
        icon: "add_alert",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "success"
      });
  },
  methods: {

  }
}
</script>

<style>

</style>
